<template>
  <div class="layout-topbar" style="background: #FFFFFF;width:calc(100% - 208 -4rem)px">
    <button class="p-link layout-menu-button" style="color: #000000;opacity: 0.65;width:5%" @click="onMenuToggle">
      <span class="pi pi-bars"></span>
    </button>
    <div @click="lookNews" class="chatIcons" v-if="iconDisplay">
      <img style="height: 20px" src="@/assets/images/news.png" />
    </div>
    <div style="padding:5px;width:100px;max-height:200px;position:fixed;top:15px;right:12vw;background:#000;text-align:center;border-radius:5px;overflow-y:auto" v-if="showList">
      <p id="projectName" class="projectNames" v-for="(item,index) in newsList" :key="index" @click="showNews(item)">{{item.project.name}}</p>
    </div>
    <div class="layout-topbar-icons">
      <!--      <Button  style="top:-10px;" class="p-button-link icon-btn">-->
      <!--        <i class="iconfont">&#xe610;</i>-->
      <!--      </Button>-->
      <!--      <Button style="top:-10px;" class="p-button-link  icon-btn" icon="pi pi-search"/>-->
      <!--      <Button style="top:-10px;" class="p-button-link icon-btn" icon="pi pi-bell"/>-->
      <!--      <Button style="top:-10px;" class="p-button-link icon-btn" icon="pi pi-cog"/>-->
      <Button @click="meoptions()" aria-controls="overlay_tmenu" class="p-button-link icon-btn" icon-pos="right" style="padding-top:3px;">
        <i style="display: inline-block; vertical-align: middle;top:-10px;font-size:26px;color:#12A25D;" class="iconfont icon-xitongguanliyuan"></i>
        <label style="display: inline-block; padding: 0px 6px 0px 6px;">{{getUserName()}}</label>
        <i style="display: inline-block; vertical-align: middle;top:-10px;font-size:8px;" class="iconfont icon-xiala"></i>
      </Button>
    </div>
  </div>
  <TieredMenu id="overlay_tmenu" ref="menu" :model="items" :popup="true"></TieredMenu>
  <!-- <ChatWindow v-if="chatDisplay" ref="chat" @close="chatDisplay=false"></ChatWindow> -->
  <ResetPassword ref="resetPassword"></ResetPassword>
</template>

<script>
import ResetPassword from "../modules/user/ResetPassword";
import ChatWindow from "@/views/modules/chat/chatWindow";
export default {
  components: { ResetPassword, ChatWindow },
  data() {
    return {
      // lists:[],
      newsList: [],
      showList: false,
      data: [],
      menu: [],
      chatDisplay: false,
      messageList: [],
      iconDisplay: false,
      projectId: null,
      userinfo: null,
      items: [
        {
          label: "修改密码",
          icon: "pi pi-pencil",
          command: () => {
            this.$refs.resetPassword.show();
          },
        },
        {
          label: "退出登录",
          icon: "pi pi-power-off",
          command: () => {
            this.$confirm.require({
              header: "确认",
              message: "确认要退出系统吗？",
              icon: "pi pi-exclamation-triangle",
              acceptLabel: "确认",
              rejectLabel: "取消",
              accept: () => {
                localStorage.removeItem("userinfo");
                this.$router.push("/loginMap");
              },
            });
          },
        },
      ],
      mainEle: null,
      lastnews: false,
    };
  },

  mounted() {
    // console.log(this.$refs);
    PubSub.subscribe("pubsubData", (msg, info) => {
      this.mainEle = info;
      // console.log(this.mainEle);
      if (
        this.mainEle.chatDisplay === true &&
        this.mainEle.messageList[0].projectId ==
          localStorage.getItem("projectId")
      ) {
        this.iconDisplay = false;
      } else {
        this.iconDisplay = true;
      }
      if (this.mainEle.messageList.length > 0) {
        // console.log(this.mainEle.messageList[0].projectId);
        let arr = [];
        arr = JSON.parse(localStorage.getItem("news"));
        if (arr.length > 0) {
          let projectIds = [];
          arr.forEach((item, index) => {
            projectIds.push(Number(item.projectId));
          });
          if (
            projectIds.indexOf(Number(this.mainEle.messageList[0].projectId)) >
            -1
          ) {
            arr.forEach((item, index) => {
              if (
                Number(this.mainEle.messageList[0].projectId) ===
                Number(item.projectId)
              ) {
                arr.splice(index, 1, this.mainEle.messageList[0]);
              }
            });
          } else {
            arr.push(this.mainEle.messageList[0]);
          }
          // for (let i = 0; i < arr.length; i++) {
          //   if (Number(this.mainEle.messageList[0].projectId) === Number(arr[i].projectId)) {
          //     arr.splice(i, 1, this.mainEle.messageList[0]);
          //   } else {
          //     arr.push(this.mainEle.messageList[0]);
          //   }
          // }
          localStorage.setItem("news", JSON.stringify(arr));
        } else {
          let str = [];
          str.push(this.mainEle.messageList[0]);
          localStorage.setItem("news", JSON.stringify(str));
        }
      }
      this.newsList = JSON.parse(localStorage.getItem("news"));
      // console.log(this.newsList);
    });
    PubSub.subscribe("display", (msg, info) => {
      // console.log(info);
      if (
        info[0].chatDisplay ||
        info[0].projectId == this.mainEle.messageList[0].projectId
      ) {
        this.iconDisplay = false;
      }
    });
    this.menu = JSON.parse(localStorage.getItem("menuList"));
  },

  methods: {
    showNews(item) {
      // console.log(item.projectId);
      this.showList = false;
      let arr = [];
      arr = JSON.parse(localStorage.getItem("news"));
      arr.forEach((items, index) => {
        // console.log(items);
        if (item.projectId == items.projectId) {
          arr.splice(index, 1);
        }
      });
      localStorage.setItem("news", JSON.stringify(arr));
      this.newsList = arr;
      if (this.newsList.length === 0) {
        this.iconDisplay = false;
      } else {
        this.iconDisplay = true;
      }
      this.mainEle.messageList.shift();
      this.mainEle.chatDisplay = true;
      this.$emit("clearJump", true);
      // this.$vuexData.commit('updata')
        this.mainEle.$nextTick(() => {
          this.mainEle.$refs.chat.init(item.projectId);
        });
    },
    lookNews() {
      if (this.newsList.length > 1) {
        this.showList = true;
      } else {
        this.showNews(this.newsList[0]);
      }
    },
    getUserName() {
      let userInfo = localStorage.getItem("userinfo");
      if (userInfo) {
        return JSON.parse(userInfo).user.name;
      }
      return "";
    },
    meoptions() {
      this.$refs.menu.toggle(event);
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
  },
};
</script>
<style>
.icon-btn {
  color: #000000 !important;
  opacity: 1;
  top: -5px;
  margin: 0px !important;
}
.chatIcons {
  position: absolute;
  top: 24px;
  right: 150px;
  width: 30px;
  height: 30px;
  background: #fff;
  /* border:1px solid #12A25D; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -13px;
  border-radius: 50%;
  cursor: pointer;
  animation: fade 1s infinite;
  -webkit-animation: fade 1s infinite;
}
.projectNames {
  margin: 5px 0 0;
  cursor: pointer;
  /* animation: fades 1s infinite;
  -webkit-animation: fades 1s infinite; */
}

@keyframes fade {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fades {
  from {
    color: #fff;
  }

  50% {
    color: #fff;
  }

  to {
    color: #000;
  }
}

@-webkit-keyframes fades {
  from {
    color: #fff;
  }

  50% {
    color: #000;
  }

  to {
    color: #fff;
  }
}
</style>
